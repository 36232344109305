import { ALL, ALL_WORD, LESSON, ASSESSMENT } from './global/constants';
import {
  PUBLIC_AUDIO_BASE,
  PUBLIC_VIDEO_BASE,
  PUBLIC_INTERACTIVE_BASE,
  PUBLIC_GUIDED_RESEARCH_BASE,
} from 'global/public-routes.constants';
import { PRIVATE_ACCOUNT_SCHOOL_JOIN_BASE } from 'global/private-routes.constants';
import { InquisitiveSubject } from 'global/graphql/scalars.type';

export const WEBSOCKET_ENDPOINT = `${process.env.REACT_APP_WEBSOCKET_ENDPOINT}/${process.env.REACT_APP_API_VERSION}`;

export const SERVERLESS_API_BASE =
  process.env.REACT_APP_SERVERLESS_ENDPOINT && process.env.REACT_APP_API_VERSION
    ? `${process.env.REACT_APP_SERVERLESS_ENDPOINT}/${process.env.REACT_APP_API_VERSION}`
    : '';

const buildServerlessEndpoint = (origin: string, endpoint: string): string =>
  SERVERLESS_API_BASE ? `${SERVERLESS_API_BASE}/${endpoint}` : origin;

export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export type LessonType = typeof LESSON | typeof ASSESSMENT;

export const SLUG_PREFIX_LESSON = 'lesson';
export const SLUG_PREFIX_ASSESSMENT = 'assessment';

export const SLUG_PREFIXES: {
  [key: string]: typeof SLUG_PREFIX_LESSON | typeof SLUG_PREFIX_ASSESSMENT;
} = {
  [LESSON]: SLUG_PREFIX_LESSON,
  [ASSESSMENT]: SLUG_PREFIX_ASSESSMENT,
};

export const NEW_TAB_SLUG_PREFIXES = [
  `${PUBLIC_GUIDED_RESEARCH_BASE}/`,
  `${PUBLIC_VIDEO_BASE}/`,
  `${PUBLIC_AUDIO_BASE}/`,
  `${PUBLIC_INTERACTIVE_BASE}/`,
];

export const VALIDATION_MESSAGE_EMAIL_REQUIRED =
  'Oops, you need to type your education email address here.';

export const VALIDATION_MESSAGE_EMAIL_TOO_LONG = 'Email address is too long.';

export const VALIDATION_MESSAGE_EMAIL_NOT_EDUCATION =
  'Psst, you need to have an education email address.';
export const VALIDATION_MESSAGE_EMAIL_NOT_VALID =
  'Oops! This email address looks invalid. Mind giving it another go?';

export const SERVERLESS_ENDPOINTS = [
  {
    origin: '/api/classtodo/print-password',
    method: HTTP_GET,
    endpoint: buildServerlessEndpoint(
      '/api/classtodo/print-password',
      'classtodo/print-password'
    ),
  },
  {
    origin: '/api/students/print-student-cards',
    method: HTTP_POST,
    endpoint: buildServerlessEndpoint(
      '/api/students/print-student-cards',
      'students/print-student-cards'
    ),
  },
  {
    origin: '/api/export-program/unit',
    method: HTTP_GET,
    endpoint: buildServerlessEndpoint(
      '/api/export-program/unit',
      'export-program/unit'
    ),
  },
  {
    origin: '/api/export-scope-and-sequence',
    method: HTTP_POST,
    endpoint: buildServerlessEndpoint(
      '/api/export-scope-and-sequence',
      'export-scope-and-sequence'
    ),
  },
  {
    origin: '/api/export-planner',
    method: HTTP_GET,
    endpoint: buildServerlessEndpoint('/api/export-planner', 'export-planner'),
  },
  {
    origin: '/api/user-invoice',
    method: HTTP_GET,
    endpoint: buildServerlessEndpoint('/api/user-invoice', 'user-invoice'),
  },
  {
    origin: '/api/download-individual-pages',
    method: HTTP_POST,
    endpoint: buildServerlessEndpoint(
      '/api/download-individual-pages',
      'download-individual-pages'
    ),
  },
  {
    origin: '/api/export-pdf',
    method: HTTP_POST,
    endpoint: buildServerlessEndpoint('/api/export-pdf', 'export-pdf'),
  },
  {
    origin: '/api/assignments/export-answer-summary',
    method: HTTP_POST,
    endpoint: buildServerlessEndpoint(
      '/api/assignments/export-answer-summary',
      'assignments/export-answer-summary'
    ),
  },
];

export type AnyObject = {
  [key: string]: any;
};

export type FieldValidation = string | boolean;

export type DropdownListItem<Value extends string = string> = {
  value: Value;
  label: string;
  labelShort?: string;
  index?: number;
};

export type FilterSubject = InquisitiveSubject | typeof ALL_WORD;

export const ALL_SUBJECTS_OPTION = { value: ALL_WORD, label: 'All Subjects' };
export const ALL_CURRICULUMS_OPTION = {
  value: ALL,
  label: 'All Curriculums',
};
export const ALL_CURRICULUM_VERSIONS_OPTION = {
  value: ALL_WORD,
  label: 'All Curriculum Versions',
};

export const learningSequenceTags = [
  {
    name: 'Colour, Symbol, Image',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Colour-Symbol-Image',
  },
  {
    name: 'Compass Point',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Compass-Points',
  },
  {
    name: 'Concept Map',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Concept-Map',
  },
  {
    name: 'Connect Extend Challenge',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Connect-Extend-Challenge',
  },
  {
    name: 'I Used to Think… Now I Think',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#I-Used-to-Think',
  },
  {
    name: 'Infographic',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Infographic',
  },
  {
    name: 'Jigsaw Strategy',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Jigsaw-Strategy',
  },
  {
    name: 'KWHL Chart',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#KWHL-Chart',
  },
  {
    name: 'Mind Map',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Mind-Map',
  },
  {
    name: 'Peel the Fruit',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Peel-the-Fruit',
  },
  {
    name: 'Plus Minus Interesting',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Plus-Minus-Interesting',
  },
  {
    name: 'Question Organiser',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Question-Organiser',
  },
  {
    name: 'See Think Wonder',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#see-think-wonder',
  },
  {
    name: 'Six Thinking Hats',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Six-Thinking-Hats',
  },
  {
    name: 'Step Inside: Perceive, Know, Care About',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#StepInside',
  },
  {
    name: 'Think Pair Share',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Think-Pair-Share',
  },
  {
    name: 'Think Puzzle Explore',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Think-Puzzle-Explore',
  },
  {
    name: 'Tug of War',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Tug-of-War',
  },
  {
    name: 'Venn Diagram',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Venn-Diagram',
  },
  {
    name: '3-2-1 Bridge Organiser',
    link: 'https://www.inquisitive.com/blog/visible-thinking/#Bridge-Organiser',
  },
  {
    name: '3-2-1 Graphic Organiser',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Graphic-Organiser',
  },
  {
    name: '10 x 2 Visual Thinking Strategy',
    link:
      'https://www.inquisitive.com/blog/visible-thinking/#Visual-Thinking-Strategy-',
  },
  {
    name: 'Engage',
    link:
      'https://www.inquisitive.com/blog/a-teachers-guide-to-the-5e-pedagogical-model/#Engage',
  },
  {
    name: 'Explore',
    link:
      'https://www.inquisitive.com/blog/a-teachers-guide-to-the-5e-pedagogical-model/#Explore',
  },
  {
    name: 'Explain',
    link:
      'https://www.inquisitive.com/blog/a-teachers-guide-to-the-5e-pedagogical-model/#Explain',
  },
  {
    name: 'Elaborate',
    link:
      'https://www.inquisitive.com/blog/a-teachers-guide-to-the-5e-pedagogical-model/#Elaborate',
  },
  {
    name: 'Evaluate',
    link:
      'https://www.inquisitive.com/blog/a-teachers-guide-to-the-5e-pedagogical-model/#Evaluate',
  },
  {
    name: 'Core',
    link: '/tags/Core',
  },
  {
    name: 'Deeper',
    link: '/tags/Deeper',
  },
  {
    name: 'Challenging',
    link: '/tags/Challenging',
  },
  {
    name: 'Analysis',
    link: '/tags/Analysis',
  },
  {
    name: 'Creativity',
    link: '/tags/Creativity',
  },
  {
    name: 'Explanation',
    link: '/tags/Explanation',
  },
  {
    name: 'Evaluation',
    link: '/tags/Evaluation',
  },
  {
    name: 'Inference',
    link: '/tags/Inference',
  },
  {
    name: 'Interpretation',
    link: '/tags/Interpretation',
  },
  {
    name: 'Observation',
    link: '/tags/Observation',
  },
  {
    name: 'Predicting',
    link: '/tags/Predicting',
  },
  {
    name: 'Problem Solving',
    link: '/tags/Problem Solving',
  },
  {
    name: 'Reflection',
    link: '/tags/Reflection',
  },
  {
    name: `Thinker's Keys`,
    link: `/tags/Thinker's Keys`,
  },
  {
    name: 'PMI',
    link: '/tags/PMI',
  },
  {
    name: '6 Hats',
    link: '/tags/6 Hats',
  },
  {
    name: 'Analyse a Contradiction',
    link: '/tags/Analyse a Contradiction',
  },
  {
    name: 'Be the Judge',
    link: '/tags/Be the Judge',
  },
  {
    name: 'Creativity with a Purpose',
    link: '/tags/Creativity with a Purpose',
  },
  {
    name: 'Explanatory Mind Map',
    link: '/tags/Explanatory Mind Map',
  },
  {
    name: 'Interpret Conflicting Sources',
    link: '/tags/Interpret Conflicting Sources',
  },
  {
    name: 'Lateral Reading',
    link: '/tags/Lateral Reading',
  },
  {
    name: 'Multi-Step Deduction',
    link: '/tags/Multi-Step Deduction',
  },
  {
    name: 'Anticipate the Future',
    link: '/tags/Anticipate the Future',
  },
  {
    name: 'Reading between the Lines',
    link: '/tags/Reading between the Lines',
  },
  {
    name: 'Reflections that Resonate',
    link: '/tags/Reflections that Resonate',
  },
  {
    name: 'Reimagine and Repurpose',
    link: '/tags/Reimagine and Repurpose',
  },
  {
    name: 'Problem Solving',
    link: '/tags/Problem Solving',
  },
  {
    name: 'Collaborative Learning',
    link: '/tags/Collaborative Learning',
  },
  {
    name: 'Explicit Teaching',
    link: '/tags/Explicit Teaching',
  },
  {
    name: 'Feedback',
    link: '/tags/Feedback',
  },
  {
    name: 'Metacognitive Strategies',
    link: '/tags/Metacognitive Strategies',
  },
  {
    name: 'Multiple Exposures',
    link: '/tags/Multiple Exposures',
  },
  {
    name: 'Questioning',
    link: '/tags/Questioning',
  },
  {
    name: 'Structuring Lessons',
    link: '/tags/Structuring Lessons',
  },
  {
    name: 'Worked Examples',
    link: '/tags/Worked Examples',
  },
  {
    name: 'Recall',
    link: '/tags/Recall',
  },
  {
    name: 'Structured Guided Inquiry',
    link: '/tags/Structured Guided Inquiry',
  },
  {
    name: 'Unstructured Guided Inquiry',
    link: '/tags/Unstructured Guided Inquiry',
  },
  {
    name: 'Open Inquiry',
    link: '/tags/Open Inquiry',
  },
  {
    name: 'Open Inquiry - Project Based Learnings',
    link: '/tags/Open Inquiry - Project Based Learnings',
  },
  {
    name: 'PIPS Inquiry',
    link: '/tags/PIPS Inquiry',
  },
  {
    name: 'Establish Mathematics Goals to Focus Learning',
    link: '/tags/Establish Mathematics Goals to Focus Learning',
  },
  {
    name: 'Implement Tasks That Promote Reasoning and Problem Solving',
    link: '/tags/Implement Tasks That Promote Reasoning and Problem Solving',
  },
  {
    name: 'Use and Connect Mathematical Representations',
    link: '/tags/Use and Connect Mathematical Representations',
  },
  {
    name: 'Facilitate Meaningful Mathematical Discourse',
    link: '/tags/Facilitate Meaningful Mathematical Discourse',
  },
  {
    name: 'Pose Purposeful Questions',
    link: '/tags/Pose Purposeful Questions',
  },
  {
    name: 'Build Procedural Fluency from Conceptual Understanding',
    link: '/tags/Build Procedural Fluency from Conceptual Understanding',
  },
  {
    name: 'Support Productive Struggle in Learning Mathematics',
    link: '/tags/Support Productive Struggle in Learning Mathematics',
  },
  {
    name: 'Elicit and Use Evidence of Student Thinking',
    link: '/tags/Elicit and Use Evidence of Student Thinking',
  },
];

export type DIRECTION = 'DIRECTION_LEFT' | 'DIRECTION_RIGHT';

export const DIRECTION_LEFT = 'DIRECTION_LEFT';
export const DIRECTION_RIGHT = 'DIRECTION_RIGHT';

export const OUTCOMES_HASH = 'outcomes';
export const PERFORMANCE_EXPECTATIONS_HASH = 'performance-expectations';
export const VOCABULARIES_HASH = 'vocabularies';
export const FOCUS_QUESTION_HASH = 'focusQuestion';
export const PHENOMENA_HASH = 'phenomena';
export const MATERIALS_HASH = 'lessonMaterials';
export const STIMULUS_HASH = 'stimulus';
export const SEQUENCE_HASH = 'sequence';
export const DIFFERENTIATED_HASH = 'differentiated';
export const TEXTUAL_CONCEPTS_HASH = 'textual-concepts';
export const BIG_IDEAS_HASH = 'big-ideas';

export const CONTENT_ITEM_TITLE_LENGTH = 60;

export const SKIP_USER_QUERIES_URLS = [PRIVATE_ACCOUNT_SCHOOL_JOIN_BASE];

export type DataSelector = { ['data-selector']?: string };

export const IS_NUMERIC_REGEX = /^[0-9]\d*$/;

export const STATIC_ASSETS = 'https://public.inquisitive.com';
