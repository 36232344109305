import { UserAccount_user_permission } from 'approot/account/__generated__/UserAccount';
import { TabOption } from 'approot/shared/tabs/tabs.constants';

export type SubscriptionPayload = {
  quantity: number;
  isWholeSchool: boolean;
  paymentMethod: string;
};

export const ACCOUNT_PROFILE = 'profile';
export const ACCOUNT_STAFF = 'staff';
export const ACCOUNT_REFER = 'refer';
export const ACCOUNT_BILLING = 'billing';
export const ACCOUNT_STUDENT_DIRECTORY = 'student-directory';

export type AccountViewKey =
  | typeof ACCOUNT_PROFILE
  | typeof ACCOUNT_STAFF
  | typeof ACCOUNT_REFER
  | typeof ACCOUNT_BILLING
  | typeof ACCOUNT_STUDENT_DIRECTORY;

export type AccountView = TabOption & {
  key: AccountViewKey;
};

export enum SchoolPlan {
  Part,
  Whole,
}

export type AccountContextType = {
  showLeaveSchoolModal: () => void;
  showLeaveSchoolDenyModal: () => void;
  isAdmin?: boolean;
  permission?: UserAccount_user_permission;
};

export const ACCOUNTS_TABS_BREAKPOINT = 1036;
